import { useCallback } from "react";
import { useStore } from "./data/store";
import { TypingInteraction } from "./data/moments";

export interface Playable {
  type: "moment" | "story";
}

export function px(value: number | undefined) {
  if (value === null || value === undefined) {
    return undefined;
  }

  const DEFAULT_HEIGHT = 1080;
  const height = useStore.getState().size?.height ?? DEFAULT_HEIGHT;
  return `${value * (height / DEFAULT_HEIGHT)}px`;
}

// creates a function that returns a px value based on the current size
export function useRpx() {
  const size = useStore((s) => s.size);

  return useCallback(
    (value: number | undefined) => {
      return px(value);
    },
    [size]
  );
}

export const typingTypes = {
  retyping: {
    lots: [
      { type: "type", duration: 1 },
      { type: "pause", duration: 0.5 },
      { type: "type", duration: 1.5 },
      { type: "pause", duration: 0.5 },
      { type: "type", duration: 1 },
    ] satisfies TypingInteraction,
    once: [
      { type: "type", duration: 2 },
      { type: "pause", duration: 0.5 },
      { type: "type", duration: 3.5 },
      { type: "pause", duration: 0.5 },
    ] satisfies TypingInteraction,
  },
  normal: {
    quick: [
      { type: "type", duration: 2 },
      { type: "pause", duration: 0.2 },
    ] satisfies TypingInteraction,
    medium: [
      { type: "type", duration: 3 },
      { type: "pause", duration: 0.5 },
    ] satisfies TypingInteraction,
    slow: [
      { type: "type", duration: 4 },
      { type: "pause", duration: 0.5 },
    ] satisfies TypingInteraction,
    superSlow: [
      { type: "type", duration: 10 },
      { type: "pause", duration: 0.5 },
    ] satisfies TypingInteraction,
  },
  instant: [] satisfies TypingInteraction,
} as const;
