import { useMomentStore } from "../../data/moment.store";
import React, { useEffect, useState } from "react";
import Message from "./message";
import { useRpx } from "../../shared";
import TitleCard from "./title-card";
import { AnimatePresence } from "framer-motion";

export default function Messages() {
  const [showTitleCard, setShowTitleCard] = useState(true);
  const messages = useMomentStore((state) => state.messages);
  const moment = useMomentStore((state) => state.currentMoment);
  const startMessages = useMomentStore((state) => state.startMessages);
  const rpx = useRpx();
  const scrollInner = React.useRef<HTMLDivElement>(null);
  const scrollContainer = React.useRef<HTMLDivElement>(null);

  function start() {
    setShowTitleCard(false);
    startMessages();
  }

  useEffect(() => {
    setTimeout(() => {
      scrollContainer.current.scrollTo({
        top: scrollInner.current.scrollHeight,
        behavior: "smooth",
      });
    }, 200);
  }, [messages.length]);

  useEffect(() => {
    if (!moment?.description?.length) {
      startMessages();
    }
  }, [moment?.description]);

  return (
    <div
      ref={scrollContainer}
      className={"hide-scrollbar h-full flex-1 overflow-y-scroll"}
    >
      <div
        ref={scrollInner}
        style={{
          paddingTop: rpx(120),
          paddingBottom: rpx(120),
        }}
      >
        <AnimatePresence>
          {moment?.description && showTitleCard && (
            <TitleCard description={moment.description} onComplete={start} />
          )}
        </AnimatePresence>

        {messages.map((message, index) => (
          <Message key={index} message={message} />
        ))}
      </div>
    </div>
  );
}
