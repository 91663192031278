import { Page, PageType } from "../../data/stories";
import React from "react";
import MasonryScroll from "./masonry-scroll";
import TitlePage from "./title-page";
import FullScreen from "./full-screen";

export default function StoryPage({ page }: { page: Page }) {
  const components: Record<PageType, () => React.ReactNode> = {
    "masonry-scroll": () => <MasonryScroll page={page} />,
    collage: () => <></>,
    "full-screen": () => <FullScreen page={page} />,
    title: () => <TitlePage page={page} />,
  };

  const Component = components[page.type];

  return <Component />;
}
