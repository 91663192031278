import { Page, Story } from "./stories";
import { create } from "zustand";

interface StoryStoreState {
  story: Story | null;
  pageIndex: number | null;
  _restart: () => void;
  _nextPage: () => void;
  playStory: (story: Story) => Promise<void>;
  onComplete: () => void;
  start: () => void;

  getPage(): Page | null;
}

export const useStoryStore = create<StoryStoreState>((set, get) => ({
  story: null,
  pageIndex: null,
  onComplete: () => null,
  playStory: (story: Story) => {
    set({ story, pageIndex: null });
    // get()._nextPage();

    return new Promise((resolve, reject) => {
      set({
        onComplete: resolve,
      });
    });
  },
  start: () => {
    get()._nextPage();
  },
  _restart: () => {
    get().playStory(get().story!);
  },
  getPage: () => get().story?.pages[get().pageIndex ?? 0],
  _nextPage: () => {
    const currentPage = get().pageIndex !== null ? get().pageIndex : -1;
    const nextPageIndex = currentPage + 1;
    const nextPage = get().story?.pages[nextPageIndex] ?? null;

    if (get().story?.pages.length === nextPageIndex) {
      // get()._restart();
      setTimeout(() => {
        get().onComplete();
      }, 3_000);
      return;
    }

    set({ pageIndex: nextPageIndex });

    setTimeout(() => {
      get()._nextPage();
    }, nextPage.duration * 1_000);
  },
}));
