import { bram, crystal, Moment } from "../moments";
import { typingTypes } from "../../shared";

export const afterFirstDate: Moment = {
  type: "moment",
  id: "after-first-date",
  title: "After the first date",
  description: "This was the morning after one of their first dates",
  persons: [crystal, bram],
  startDate: "2019-03-20",
  chats: [
    {
      from: crystal.id,
      type: "text",
      typing: typingTypes.normal.quick,
      content: "Last night was so fun ☺️️️",
      delay: 2,
    },
    {
      from: bram.id,
      type: "text",
      typing: typingTypes.normal.medium,
      content:
        "I had such a great time :), hope you didn't have a headache this morning",
      delay: 2,
    },
    {
      from: crystal.id,
      type: "text",
      typing: typingTypes.normal.quick,
      content: "Nah. Nothing too bad. 🙃what about you?",
      delay: 2,
    },
    {
      from: bram.id,
      type: "text",
      typing: typingTypes.normal.medium,
      content:
        "my head is pretty good, I've been couch working in my underpants this whole morning tho 😂",
      delay: 2,
    },
    {
      from: crystal.id,
      type: "text",
      typing: typingTypes.normal.quick,
      content:
        "Omg me too. Lol literally working on music in panties and a tank top 😅",
      delay: 2,
    },
    {
      from: bram.id,
      type: "text",
      typing: typingTypes.normal.medium,
      content:
        "Hehe I think that can be considered the good life! Also must be nice to look at 😏",
      delay: 2,
    },
    {
      from: bram.id,
      type: "text",
      typing: typingTypes.normal.medium,
      content: "what music are you working on?",
      delay: 2,
    },
    {
      from: crystal.id,
      type: "text",
      typing: typingTypes.normal.quick,
      content:
        "Working on this little film score gig. And updating my resume because the Grammys people want it. I'm going to do some coordinating work for them this August.",
      delay: 2,
    },
    {
      from: crystal.id,
      type: "text",
      typing: typingTypes.normal.quick,
      content: "Haha yes the freedom to work from home is lovely",
      delay: 2,
    },
    {
      from: crystal.id,
      type: "text",
      typing: typingTypes.normal.quick,
      content: "☺️️️",
      delay: 2,
    },
    {
      from: bram.id,
      type: "text",
      typing: typingTypes.normal.medium,
      content: "Oh wow! That sounds like a super exciting job, nice going :)",
      delay: 2,
    },
    {
      from: crystal.id,
      type: "text",
      typing: typingTypes.normal.quick,
      content:
        "Thanks! It should be fun. It will be a very busy month. August 8 til September 9",
      delay: 2,
    },
    {
      from: crystal.id,
      type: "text",
      typing: typingTypes.normal.quick,
      content:
        "So if you come back and visit, you should definitely come before OR after that 😉",
      delay: 2,
    },
    {
      from: crystal.id,
      type: "text",
      typing: typingTypes.normal.quick,
      content: "Preferably before",
      delay: 2,
    },
    {
      from: bram.id,
      type: "text",
      typing: typingTypes.normal.medium,
      content: "Damn yeah, I can imagine!",
      delay: 2,
    },
    {
      from: bram.id,
      type: "text",
      typing: typingTypes.normal.medium,
      content: "wink wink, I'd like that ;)",
      delay: 2,
    },
    {
      from: bram.id,
      type: "text",
      typing: typingTypes.normal.medium,
      content:
        "I'll see what I can do once I'm back, but you know I'm curious to tomorrow",
      delay: 2,
    },
    {
      from: crystal.id,
      type: "text",
      typing: typingTypes.normal.quick,
      content:
        "Of course! Haha we will have fun tomorrow. Anything in particular you're interested in doing tomorrow?",
      delay: 2,
    },
    {
      from: bram.id,
      type: "text",
      typing: typingTypes.normal.medium,
      content:
        "Besides being interested in you, I don’t really have anything on my mind 🙂 . But I’ll have a look for sure!",
      delay: 2,
    },
    {
      from: crystal.id,
      type: "text",
      typing: typingTypes.normal.quick,
      content:
        "I can think of a few options and let you know during the day tomorrow :)",
      delay: 2,
    },
    {
      from: bram.id,
      type: "text",
      typing: typingTypes.normal.medium,
      content: "Alright! Sounds great ;)",
      delay: 2,
    },
    {
      from: bram.id,
      type: "text",
      typing: typingTypes.normal.medium,
      content: "Hey what time should we aim for tomorrow approx?",
      delay: 4,
    },
    {
      from: crystal.id,
      type: "text",
      typing: typingTypes.normal.quick,
      content:
        "The earliest i can be free is 6. I can TRY to get off early though",
      delay: 2,
    },
    {
      from: crystal.id,
      type: "text",
      typing: typingTypes.normal.quick,
      content: "I will text you during the day",
      delay: 2,
    },
    {
      from: bram.id,
      type: "text",
      typing: typingTypes.normal.medium,
      content: "Perfect! No stress, keep me posted :)",
      delay: 2,
    },
    {
      from: crystal.id,
      type: "text",
      typing: typingTypes.normal.quick,
      content: "👍🏼",
      delay: 2,
    },
    {
      from: crystal.id,
      type: "text",
      typing: typingTypes.normal.quick,
      content:
        "Hey! :) sorry I am so busy at work today and it's made it hard to plan ahead for tonight. We will have fun though, i promise. Since it's raining, maybe you should just plan on meeting me at my place around 6:30? And we can figure out something from there! If I think of a better idea before then though I will let you know. :)",
      delay: 2,
    },

    {
      from: crystal.id,
      type: "text",
      typing: typingTypes.normal.quick,
      content:
        "Have you gone to a comedy show yet? LA is famous for its comedy clubs!",
      delay: 2,
    },
    {
      from: bram.id,
      type: "text",
      typing: typingTypes.normal.quick,
      content:
        "Hey! No worries! That sounds like a really good plan :) let’s check that out!",
      delay: 2,
    },
    {
      from: bram.id,
      type: "text",
      typing: typingTypes.normal.quick,
      content:
        "Is it okay if I bring my luggage already with me, then I don’t have to go back to my sketchy neighborhood to checkout 😂",
      delay: 2,
    },
    {
      from: crystal.id,
      type: "text",
      typing: typingTypes.normal.quick,
      content: "Hahaha okay! Absolutely.  Why not, right? 😂",
      delay: 2,
    },
    {
      from: crystal.id,
      type: "text",
      typing: typingTypes.normal.quick,
      content: "I'm sorry your Airbnb was in such a sketchy area!",
      delay: 2,
    },
    {
      from: bram.id,
      type: "text",
      typing: typingTypes.normal.quick,
      content:
        "Haha it’s actually kinda funny, this guy tried to turn it a bit into a ghost house. I’ll show you a video when I’m there ;)",
      delay: 2,
    },
    {
      from: bram.id,
      type: "text",
      typing: typingTypes.normal.quick,
      content: "Thanks :)",
      delay: 2,
    },
    {
      from: bram.id,
      type: "text",
      typing: typingTypes.normal.quick,
      content: "I’m not sure how good it is for Airbnb reviews tho 😜",
      delay: 2,
    },
    {
      from: crystal.id,
      type: "text",
      typing: typingTypes.normal.quick,
      content: "Ooh yeah? I love that stuff! Haha can't wait to see!",
      delay: 2,
    },
    {
      from: crystal.id,
      type: "text",
      typing: typingTypes.normal.quick,
      content:
        "I found a really good comedy show that's actually walking distance from where I am. I live close to the best comedy clubs. Tickets are kind of expensive though! $27 each... I don't mind if you don't though! I think it would be worth it.",
      delay: 2,
    },
    {
      from: bram.id,
      type: "text",
      typing: typingTypes.normal.quick,
      content:
        "Awesome! You live close to a whole lotta good stuff ;) Yeah let’s do it :) I’m excited! Where can I buy the tickets for us both?",
      delay: 2,
    },
    {
      from: crystal.id,
      type: "text",
      typing: typingTypes.normal.quick,
      content:
        "Aww it's okay, i can get them! How about you let me get these tickets and I will let you pay for other stuff tonight. Deal?",
      delay: 2,
    },
    {
      from: bram.id,
      type: "text",
      typing: typingTypes.normal.quick,
      content:
        "Okay okay, that’s really kind of you :) better pick some good stuff from the menu then!",
      delay: 2,
    },
    {
      from: crystal.id,
      type: "text",
      typing: typingTypes.normal.quick,
      content: "Okay sounds good ;)",
      delay: 2,
    },
  ],
};
