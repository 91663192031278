import { Chat } from "../../data/moments";
import React, { CSSProperties } from "react";
import { useRpx } from "../../shared";
import Media from "../media";

export default function MessageMedia({ message }: { message: Chat }) {
  const rpx = useRpx();

  const style: CSSProperties = {
    maxHeight: rpx(600),
    maxWidth: "100%",
    objectFit: message.type === "video" ? "contain" : "cover",
  };

  if (message.type === "text") {
    return null;
  }

  return (
    <div
      className={"w-full overflow-hidden"}
      style={{
        marginTop: rpx(5),
        borderRadius: rpx(15),
        maxHeight: rpx(600),
      }}
    >
      <Media media={{ type: message.type, url: message.url }} style={style} />
    </div>
  );
}
