import React, { useEffect, useMemo, useState } from "react";
import { motion } from "framer-motion";
import { useRpx } from "../../shared";

export default function TitleCard({
  description,
  onComplete,
  delayAfterComplete = 2000,
}: {
  description: string | Array<string>;
  onComplete: () => void;
  delayAfterComplete?: number;
}) {
  const [playIndex, setPlayIndex] = useState(0);
  const [wordIndex, setWordIndex] = useState(0);

  const currentSentence = useMemo(() => {
    if (Array.isArray(description)) {
      return description?.[playIndex];
    }

    return description;
  }, [playIndex]);

  const words = useMemo(() => {
    return currentSentence?.split(" ");
  }, [currentSentence]);

  const rpx = useRpx();

  function complete(id: number) {
    clearInterval(id);

    const exit = () => {
      setTimeout(onComplete, delayAfterComplete);
    };

    if (!Array.isArray(description)) {
      exit();
      return;
    }

    if (playIndex + 1 >= description.length) {
      exit();
      return;
    }

    setTimeout(() => {
      setWordIndex(0);
      setPlayIndex((i) => i + 1);
    }, 1000);
  }

  useEffect(() => {
    const id = setInterval(() => {
      setWordIndex((lastIndex) => {
        if (lastIndex >= words?.length) {
          complete(id);
          return lastIndex;
        }

        return lastIndex + 1;
      });
    }, 300);

    return () => {
      clearInterval(id);
    };
  }, [words]);

  const playWords = words?.slice(0, wordIndex);

  return (
    <motion.div
      initial={{
        opacity: 0,
      }}
      animate={{
        opacity: 1,
      }}
      exit={{
        opacity: 0,
      }}
      className={
        "absolute top-0 z-50 flex h-full w-full flex-col justify-center bg-pink-200 font-black text-teal-400"
      }
    >
      {playWords?.map((word, i) => (
        <motion.div
          layoutId={`sentence_${playIndex}_word_${i}_${playIndex}`}
          style={{
            fontSize: rpx(100),
            lineHeight: rpx(78),
            marginLeft: rpx(40),
          }}
          initial={{
            opacity: 0,
            x: 10,
          }}
          animate={{
            opacity: 1,
            x: 0,
          }}
        >
          {word}
        </motion.div>
      ))}
    </motion.div>
  );
}
