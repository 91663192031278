import React from "react";
import { Page } from "../../data/stories";

export default function TitlePage({ page }: { page: Page }) {
  return (
    <div
      className={
        "flex h-full w-full items-center justify-center text-6xl text-white"
      }
    >
      {page.content}
    </div>
  );
}
