import { Story } from "../stories";

export const pets: Story = {
  type: "story",
  title: "The Pets and Us",
  description: [
    "a very small teeny tiny selection",
    "of our furry friends and us",
  ],
  pages: [
    {
      type: "full-screen",
      id: "pets-1",
      content: "",
      duration: 20,
      media: [
        {
          type: "image",
          url: new URL(
            "/assets/images/stories/pets/IMG_2227.jpeg?width=1000&as=avif&quality=50",
            import.meta.url
          ).toString(),
        },
        {
          type: "image",
          url: new URL(
            "/assets/images/stories/pets/IMG_1904.jpeg?width=1000&as=avif&quality=50",
            import.meta.url
          ).toString(),
        },
        {
          type: "image",
          url: new URL(
            "/assets/images/stories/pets/19d06da0-3137-48fd-b002-be8df24feae7.jpeg?width=1000&quality=50",
            import.meta.url
          ).toString(),
        },
        {
          type: "video",
          url: new URL(
            "/assets/images/stories/pets/951D0CE7-ACAC-4425-A33D-B1A8E5D1140.mp4",
            import.meta.url
          ).toString(),
        },
        {
          type: "image",
          url: new URL(
            "/assets/images/stories/pets/e4cb066e-79ed-4e76-bba5-f303c9768713.jpeg?width=1000&quality=50",
            import.meta.url
          ).toString(),
        },
      ],
    },
    {
      id: "pets-2",
      type: "masonry-scroll",
      duration: 40,
      content: "",
      title: "",
      media: [
        {
          type: "image",
          url: new URL(
            "/assets/images/stories/pets/IMG_1492.jpeg?width=1000&as=avif&quality=50",
            import.meta.url
          ).toString(),
        },
        {
          type: "image",
          url: new URL(
            "/assets/images/stories/pets/IMG_1942.jpeg?width=1000&as=avif&quality=50",
            import.meta.url
          ).toString(),
        },
        {
          type: "image",
          url: new URL(
            "/assets/images/stories/pets/IMG_2155.jpeg?width=1000&as=avif&quality=50",
            import.meta.url
          ).toString(),
        },
        {
          type: "image",
          url: new URL(
            "/assets/images/stories/pets/IMG_3554.jpeg?width=1000&as=avif&quality=50",
            import.meta.url
          ).toString(),
        },
        {
          type: "image",
          url: new URL(
            "/assets/images/stories/pets/IMG_3714.jpeg?width=1000&as=avif&quality=50",
            import.meta.url
          ).toString(),
        },
        {
          type: "image",
          url: new URL(
            "/assets/images/stories/pets/IMG_3715.jpeg?width=1000&as=avif&quality=50",
            import.meta.url
          ).toString(),
        },
        {
          type: "image",
          url: new URL(
            "/assets/images/stories/pets/IMG_4102.jpeg?width=1000&as=avif&quality=50",
            import.meta.url
          ).toString(),
        },
        {
          type: "image",
          url: new URL(
            "/assets/images/stories/pets/IMG_4194.jpeg?width=1000&as=avif&quality=50",
            import.meta.url
          ).toString(),
        },
        {
          type: "image",
          url: new URL(
            "/assets/images/stories/pets/IMG_4655.jpeg?width=1000&as=avif&quality=50",
            import.meta.url
          ).toString(),
        },
        {
          type: "image",
          url: new URL(
            "/assets/images/stories/pets/IMG_4719.jpeg?width=1000&as=avif&quality=50",
            import.meta.url
          ).toString(),
        },
      ],
    },
    {
      type: "full-screen",
      id: "pets-3",
      content: "",
      duration: 20,
      media: [
        {
          type: "image",
          url: new URL(
            "/assets/images/stories/pets/pets1.jpeg?width=1000&as=avif&quality=50",
            import.meta.url
          ).toString(),
        },
        {
          type: "image",
          url: new URL(
            "/assets/images/stories/pets/pets2.jpeg?width=1000&as=avif&quality=50",
            import.meta.url
          ).toString(),
        },
        {
          type: "image",
          url: new URL(
            "/assets/images/stories/pets/pets3.jpeg?width=1000&as=avif&quality=50",
            import.meta.url
          ).toString(),
        },
        {
          type: "image",
          url: new URL(
            "/assets/images/stories/pets/pets4.jpeg?width=1000&as=avif&quality=50",
            import.meta.url
          ).toString(),
        },
        {
          type: "image",
          url: new URL(
            "/assets/images/stories/pets/pets5.jpeg?width=1000&as=avif&quality=50",
            import.meta.url
          ).toString(),
        },
        {
          type: "image",
          url: new URL(
            "/assets/images/stories/pets/pets6.jpeg?width=1000&as=avif&quality=50",
            import.meta.url
          ).toString(),
        },
        {
          type: "image",
          url: new URL(
            "/assets/images/stories/pets/pets7.jpeg?width=1000&as=avif&quality=50",
            import.meta.url
          ).toString(),
        },
      ],
    },
  ],
};
