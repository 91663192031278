import { Playable } from "../shared";

export interface Moment extends Playable {
  id: string;
  title: string;
  description: string | Array<string>;
  startDate: string;
  chats: Array<Chat>;
  persons: Array<Person>;
}

export type TypingInteraction = Array<{
  type: "pause" | "type";
  duration: number;
}>;

export interface Chat {
  typing: TypingInteraction;
  content: string;
  type: "text" | "image" | "video" | "announcement";
  delay: number;
  from: string;
  url?: string;

  show?: boolean;
  isTyping?: boolean;
}

export interface Person {
  name: string;
  avatar: string;
  id: string;
  color: string;
  secondaryColor: string;
}

export const crystal: Person = {
  name: "Crystal Frost",
  avatar: new URL(
    "../../assets/images/crystal_profile.jpeg",
    import.meta.url
  ).toString(),
  color: "#f43f5e",
  secondaryColor: "#fbcfe8",
  id: "crystal",
};

export const bram: Person = {
  name: "Bram Veerman",
  avatar: new URL(
    "../../assets/images/bram_profile.jpg",
    import.meta.url
  ).toString(),
  color: "#155e75",
  secondaryColor: "#99f6e4",
  id: "bram",
};

export const persons: Array<Person> = [crystal, bram];
