import { bram, crystal, Moment } from "../moments";
import { typingTypes } from "../../shared";

export const gibby: Moment = {
  type: "moment",
  id: "after-first-date",
  title: "After the first date",
  description: [
    "Bram not so secretly decides to download Gibby",
    "one of Crystal's biggest film scores",
  ],
  persons: [crystal, bram],
  startDate: "2019-03-20",
  chats: [
    {
      from: bram.id,
      type: "image",
      typing: typingTypes.normal.medium,
      content: "",
      url: new URL(
        "/assets/images/chats/gibby/scene.jpg?width=600",
        import.meta.url
      ).toString(),
      delay: 1,
    },
    {
      from: crystal.id,
      type: "text",
      typing: typingTypes.normal.quick,
      content: "Omg are you watching Gibby?!?!",
      delay: 1,
    },
    {
      from: bram.id,
      type: "text",
      typing: typingTypes.normal.quick,
      content: "Hahahaha yesss!!",
      delay: 1,
    },
    {
      from: crystal.id,
      type: "text",
      typing: typingTypes.normal.quick,
      content: "😅🙈🙈🙈",
      delay: 1,
    },
    {
      from: bram.id,
      type: "text",
      typing: typingTypes.normal.quick,
      content: "Damn well spotted!!",
      delay: 1,
    },
    {
      from: bram.id,
      type: "text",
      typing: typingTypes.normal.quick,
      content: "😃😃😃😃",
      delay: 1,
    },
    {
      from: crystal.id,
      type: "text",
      typing: typingTypes.normal.quick,
      content:
        "I mean I scored that whole film and did music supervision on it so I have the thing learned back to back",
      delay: 1,
    },
    {
      from: crystal.id,
      type: "text",
      typing: typingTypes.normal.quick,
      content: "Hahaha!",
      delay: 1,
    },
    {
      from: crystal.id,
      type: "text",
      typing: typingTypes.normal.quick,
      content: "Okay let me know what you think 😘",
      delay: 1,
    },
    {
      from: bram.id,
      type: "text",
      typing: typingTypes.normal.quick,
      content: "Hmm so sexy baby 😘",
      delay: 1,
    },
    {
      from: bram.id,
      type: "text",
      typing: typingTypes.normal.quick,
      content: "Yeah I will do my sexy rockstar😏",
      delay: 1,
    },
    {
      from: crystal.id,
      type: "text",
      typing: typingTypes.normal.quick,
      content: "😘❤",
      delay: 1,
    },
    {
      from: crystal.id,
      type: "text",
      typing: typingTypes.normal.quick,
      content: "Can't believe you are watching Gibby haha 🙈",
      delay: 1,
    },
    {
      from: crystal.id,
      type: "text",
      typing: typingTypes.normal.quick,
      content: "I am flattered though, truly. I'm so into you baba 😏",
      delay: 1,
    },
    {
      from: bram.id,
      type: "text",
      typing: typingTypes.normal.quick,
      content: "Hmmm it’s been on my list you know 😏",
      delay: 1,
    },
    {
      from: bram.id,
      type: "text",
      typing: typingTypes.normal.quick,
      content: "Baby I already love it!!",
      delay: 1,
    },
    {
      from: bram.id,
      type: "text",
      typing: typingTypes.normal.quick,
      content:
        "Hmm I’m so into you too and I can’t wait to be inside of you again 😏",
      delay: 1,
    },
    {
      from: crystal.id,
      type: "text",
      typing: typingTypes.normal.quick,
      content:
        "Awww you're so sweet baba I'm blushing 🤭 I think it is way too easy for you to make me blush... 😏",
      delay: 1,
    },
    {
      from: crystal.id,
      type: "text",
      typing: typingTypes.normal.quick,
      content: "Neither can I... hmmm god.",
      delay: 1,
    },
    {
      from: crystal.id,
      type: "text",
      typing: typingTypes.normal.quick,
      content: "On your list, like actually on your to-do list?",
      delay: 1,
    },
    {
      from: crystal.id,
      type: "text",
      typing: typingTypes.normal.quick,
      content: "😮🤭",
      delay: 1,
    },
    {
      from: bram.id,
      type: "text",
      typing: typingTypes.normal.quick,
      content:
        "Sounds like you must have had so much fun making this, I really like those musical responses!",
      delay: 1,
    },
    {
      from: bram.id,
      type: "text",
      typing: typingTypes.normal.quick,
      content: "🤤🤤",
      delay: 1,
    },
    {
      from: bram.id,
      type: "text",
      typing: typingTypes.normal.quick,
      content:
        "Well it’s on my movie section, but it did not have to remind me, miraculously stayed on my mind baby 😘",
      delay: 1,
    },
    {
      from: bram.id,
      type: "text",
      typing: typingTypes.normal.quick,
      content:
        "Miraculously can be changed to obviously, since you cause miracles in my brain constantly ❤️❤️",
      delay: 1,
    },
    {
      from: crystal.id,
      type: "text",
      typing: typingTypes.normal.quick,
      content: "Awwww melting baba....",
      delay: 1,
    },
    {
      from: crystal.id,
      type: "text",
      typing: typingTypes.normal.quick,
      content:
        "Yeah since I made that score several years ago when I hear it now I hear all kinds of problems, but the music itself, like the music I wrote aside from the quality of the recording, still passes most of my tests. I really hated the sound editor though. He mixed the music so quietly during so many scenes! Especially certain frequencies seem practically muted. I asked the producer who hired me, 'what's the point of hiring a composer to do a full orchestral score if no one can hear it?!' 😜 my favorite music to write for that movie was the big kitchen scene, and the doorbell scene.",
      delay: 1,
    },
    {
      from: crystal.id,
      type: "text",
      typing: typingTypes.normal.quick,
      content: "You will know both of those scenes when you get to them 😘",
      delay: 1,
    },
    {
      from: bram.id,
      type: "text",
      typing: typingTypes.normal.quick,
      content: "❤️❤️",
      delay: 1,
    },
    {
      from: bram.id,
      type: "text",
      typing: typingTypes.normal.quick,
      content:
        "I really like it, very playful!! Music wise I’ve got nothing, indeed the music could’ve been more part of the movie and a good amount louder! I’d love to hear you walk me through a scene sometime :)) hmm did he also do the lip-syncing of the dubs or was that someone else?",
      delay: 1,
    },
    {
      from: bram.id,
      type: "text",
      typing: typingTypes.normal.quick,
      content:
        "I might be at the big kitchen scene rn, is that where Gibby is making avant-garde style omelettes on the ground 😆",
      delay: 1,
    },
    {
      from: bram.id,
      type: "text",
      typing: typingTypes.normal.quick,
      content: "Hahaha that’s a funny scene tho 😜",
      delay: 1,
    },
    {
      from: bram.id,
      type: "text",
      typing: typingTypes.normal.quick,
      content: "I like how the music keeps building up :))",
      delay: 1,
    },
    {
      from: crystal.id,
      type: "text",
      typing: typingTypes.normal.quick,
      content:
        "Hahah yes exactly. That movie is so silly omg 😅 I am still so sad that they didn't take the offer from Lionsgate. I would have so much money right now if they had taken it! Lionsgate loved it but they didn't offer very much money for it and distribution because it's obviously a low-budget family movie. Lionsgate wanted to make two sequels",
      delay: 1,
    },
    {
      from: crystal.id,
      type: "text",
      typing: typingTypes.normal.quick,
      content:
        "But the team of producers was a bunch of head strong, proud men who went with another American distributor that offered way more money up front but barely got it any exposure. I feel like they can barely even call themselves a distributor, actually lol.",
      delay: 1,
    },
    {
      from: bram.id,
      type: "text",
      typing: typingTypes.normal.quick,
      content:
        "Oh damn baby I can imagine!! What’s the saying, one in the hand is like two in the tree? (I’m messing this up, I’ll google 😜)",
      delay: 1,
    },
    {
      from: bram.id,
      type: "text",
      typing: typingTypes.normal.quick,
      content:
        "I think I’m at the doorbell scene! The part before has great music baby, I get so turned on imagining you working on this 😏",
      delay: 1,
    },
    {
      from: bram.id,
      type: "text",
      typing: typingTypes.normal.quick,
      content: "_A bird in hand is worth two in the bush_",
      delay: 1,
    },
    {
      from: bram.id,
      type: "text",
      typing: typingTypes.normal.quick,
      content: "There we go 😜",
      delay: 1,
    },
    {
      from: crystal.id,
      type: "text",
      typing: typingTypes.normal.quick,
      content: "Omg you are so adorable!!!!!! 😍😍😍😍",
      delay: 1,
    },
    {
      from: bram.id,
      type: "text",
      typing: typingTypes.normal.quick,
      content: "😘😘",
      delay: 1,
    },
    {
      from: crystal.id,
      type: "text",
      typing: typingTypes.normal.quick,
      content:
        "I don't even know this saying! Is this an American saying i should know?",
      delay: 1,
    },
    {
      from: crystal.id,
      type: "text",
      typing: typingTypes.normal.quick,
      content: "Maybe it's English... Like from England..? 🤔",
      delay: 1,
    },
    {
      from: crystal.id,
      type: "text",
      typing: typingTypes.normal.quick,
      content:
        "But oh my goodness I totally agree! And it's sexy that you knew a little proverb about this 😏😏",
      delay: 1,
    },
    {
      from: crystal.id,
      type: "text",
      typing: typingTypes.normal.quick,
      content:
        "Lol of all the movies you could be watching right now, and Baba picks Gibby 😝",
      delay: 1,
    },
  ],
};
