import React, { useEffect } from "react";
import ChatHeader from "./chat-header";
import { meetCute } from "../../data/moments/after-meet-cute";
import Messages from "../messages/messages";
import { bramTripEnd } from "../../data/moments/bram-trip-end";
import { tortillaBlanket } from "../../data/moments/tortilla-blanket";
import { sample } from "../../data/moments/sample-moment";
import { useMomentStore } from "../../data/moment.store";
import { afterFirstDate } from "../../data/moments/after-first-date";

const background = new URL(
  "/assets/images/whatsapp-bg.jpg",
  import.meta.url
).toString();

export default function Chat() {
  const playMoment = useMomentStore((state) => state.playMoment);
  const messages = useMomentStore((state) => state.messages);
  const currentMoment = useMomentStore((state) => state.currentMoment);

  useEffect(() => {
    // playMoment(tortillaBlanket);
    // playMoment(bramTripEnd);
    // playMoment(meetCute);
    // playMoment(afterFirstDate);
  }, []);

  if (!currentMoment) {
    return null;
  }

  return (
    <div
      className={
        "h-full w-full overflow-hidden bg-neutral-100 bg-cover bg-center"
      }
      style={{ backgroundImage: `url(${background})` }}
    >
      <ChatHeader />
      <Messages />
    </div>
  );
}
