import { Page } from "../../data/stories";
import React, { useEffect, useMemo, useState } from "react";
import Media from "../media";
import { motion, AnimatePresence } from "framer-motion";
import { useRpx } from "../../shared";

export default function FullScreen({ page }: { page: Page }) {
  const [currentIndex, setCurrentIndex] = useState(0);

  const [showBackdrop, setShowBackdrop] = useState(false);

  const duration = useMemo(() => {
    return page.duration / page.media.length;
  }, [page]);

  useEffect(() => {
    // loop over all the media inside of the page using setInterval
    // and update the currentIndex

    const interval = setInterval(() => {
      setCurrentIndex((currentIndex) => {
        if (currentIndex + 1 < page.media.length) {
          return currentIndex + 1;
        } else {
          return 0;
        }
      });
    }, duration * 1_000);

    return () => {
      clearInterval(interval);
    };
  }, [page.id, duration]);

  const media = useMemo(() => page.media?.[currentIndex], [page, currentIndex]);

  useEffect(() => {
    setShowBackdrop(false);

    setTimeout(() => setShowBackdrop(true), 300);
  }, [currentIndex]);

  return (
    <div className={"h-full w-full bg-black"}>
      <Indicator
        currentIndex={currentIndex}
        duration={duration}
        totalMedia={page.media?.length}
      />

      <AnimatePresence mode={"sync"}>
        {showBackdrop && (
          <motion.div
            initial={{ opacity: 0 }}
            animate={{ opacity: 0.25 }}
            exit={{ opacity: 0 }}
            className={"absolute z-0 h-full w-[200%] blur-md"}
          >
            <motion.div
              className={"h-full w-full"}
              animate={{
                x: ["0%", "-25%"],
              }}
              transition={{
                repeat: Infinity,
                duration: 20,
                repeatType: "mirror",
                delay: 0.5,
              }}
            >
              <Media
                key={currentIndex}
                media={media}
                style={{
                  width: "100%",
                  height: "100%",
                  objectFit: "cover",
                }}
              />
            </motion.div>
          </motion.div>
        )}

        {media && (
          <motion.div
            key={currentIndex}
            className={"absolute left-0 top-0 h-full w-full"}
            initial={{
              opacity: 0,
              x: "10px",
            }}
            animate={{
              opacity: 1,
              x: "10px",
            }}
            exit={{
              opacity: 0,
              x: "-10px",
            }}
            transition={{
              duration: 0.6,
              // delay: 0.2,
            }}
          >
            <div className={"relative z-10 h-full w-full"}>
              <Media
                key={currentIndex}
                media={media}
                style={{
                  height: "100%",
                  width: "100%",
                  objectFit: "scale-down",
                }}
              />
            </div>
          </motion.div>
        )}
      </AnimatePresence>
    </div>
  );
}

function Indicator({
  currentIndex,
  totalMedia,
  duration,
}: {
  currentIndex: number;
  totalMedia: number;
  duration: number;
}) {
  const rpx = useRpx();

  return (
    <div className={"absolute top-2 z-50 flex w-full gap-2 px-3"}>
      {Array.from({ length: totalMedia }).map((_, index) => (
        <div
          key={`indicator-${index}`}
          className={"w-full bg-white bg-opacity-25"}
          style={{ height: rpx(3) }}
        >
          <motion.div
            className={"h-full bg-white"}
            animate={{
              width: currentIndex >= index ? "100%" : "0%",
            }}
            initial={{
              width: "0%",
            }}
            transition={{
              duration: duration,
            }}
          ></motion.div>
        </div>
      ))}
    </div>
  );
}
