import React, { ReactNode, useMemo } from "react";
import { useRpx } from "../../shared";

interface TypographyProps {
  children: ReactNode;
  size?: number;
  font?: "instrument";
  className?: string;
}

export default function Typography({
  children,
  size = 16,
  font = "instrument",
  className = "",
}: TypographyProps) {
  const rpx = useRpx();

  const classes = useMemo(() => {
    const fontClass = {
      instrument: "font-instrument",
    }[font];

    return `${fontClass}`;
  }, [font]);

  return (
    <div className={`${classes} ${className}`} style={{ fontSize: rpx(size) }}>
      {children}
    </div>
  );
}
