import { bram, crystal, Moment } from "../moments";
import { typingTypes } from "../../shared";

export const tortillaBlanket: Moment = {
  type: "moment",
  persons: [crystal, bram],
  title: `Bram sends a Tortilla Blanket`,
  description: [
    "Bram wants to wrap his arms around Crystal, but is in NL.🇳🇱",
    "From the Netherlands he sends her a little gag gift.🌯",
  ],
  id: "tortilla-blanket",
  startDate: "May 28, 2019",
  chats: [
    {
      from: crystal.id,
      type: "image",
      typing: typingTypes.normal.medium,
      content: "",
      url: new URL(
        "/assets/images/chats/tortilla/package.gif?width=600",
        import.meta.url
      ).toString(),
      delay: 1,
    },
    {
      from: bram.id,
      type: "text",
      typing: typingTypes.normal.quick,
      content: "Ooh shit!!! Yes open it :)))",
      delay: 0,
    },
    {
      from: bram.id,
      type: "text",
      typing: typingTypes.normal.medium,
      content: "Aah that's soo much sooner than it showed on amazon 😃",
      delay: 1,
    },
    {
      from: crystal.id,
      type: "text",
      typing: typingTypes.normal.quick,
      content: "Ahhh wait I should?!",
      delay: 1,
    },
    {
      from: bram.id,
      type: "text",
      typing: typingTypes.normal.quick,
      content: "❤️❤️❤️❤️",
      delay: 0,
    },
    {
      from: crystal.id,
      type: "text",
      typing: typingTypes.normal.quick,
      content: "Ahhhh I should wait til I have more time though!",
      delay: 1,
    },
    {
      from: crystal.id,
      type: "text",
      typing: typingTypes.normal.quick,
      content: "Right?",
      delay: 0,
    },
    {
      from: bram.id,
      type: "text",
      typing: typingTypes.normal.quick,
      content: "Okay okay, you can open it whenever you want!",
      delay: 1,
    },
    {
      from: bram.id,
      type: "text",
      typing: typingTypes.normal.quick,
      content: "I'm sure Mr Cat gives you more points after work 😂",
      delay: 1,
    },
    {
      from: "system",
      type: "announcement",
      content: `Mr Cat is something we made up, he's kind of a manager. If it wasn't for him, we'd never never stop messaging.`,
      typing: typingTypes.instant,
      delay: 0,
    },
    {
      from: bram.id,
      type: "text",
      typing: typingTypes.normal.quick,
      content: "It's fully up to you my sexy baby!!",
      delay: 2,
    },
    {
      from: crystal.id,
      type: "text",
      typing: typingTypes.normal.medium,
      content:
        "Ahhhhh I want to open it! Sometimes I can't tell whether I am having self-discipline for no reason or for a good reason 🤔 (Probably because often self-discipline doesn't come naturally so I just try to have it at random times to make sure I still know how 😜)",
      delay: 1,
    },
    {
      from: bram.id,
      type: "text",
      typing: typingTypes.normal.quick,
      content: "Yeah you can open it if you want 😃",
      delay: 1,
    },
    {
      from: bram.id,
      type: "text",
      typing: typingTypes.normal.quick,
      content: "It's all yours my sexy burrito 😘🌯",
      delay: 0,
    },
    {
      from: crystal.id,
      type: "text",
      typing: typingTypes.normal.quick,
      content: "Ahhh okay",
      delay: 1,
    },
    {
      from: crystal.id,
      type: "text",
      typing: typingTypes.normal.quick,
      content: "Ima do it. 😝",
      delay: 1,
    },
    {
      from: bram.id,
      type: "text",
      typing: typingTypes.normal.quick,
      content: "Yess!!",
      delay: 1,
    },
    {
      from: crystal.id,
      type: "text",
      typing: typingTypes.normal.quick,
      content: "Wait. I have to find scissors",
      delay: 1,
    },
    {
      from: crystal.id,
      type: "text",
      typing: typingTypes.normal.quick,
      content: "They taped this really good 😅",
      delay: 1,
    },
    {
      from: bram.id,
      type: "text",
      typing: typingTypes.normal.quick,
      content: "All the time you need",
      delay: 1,
    },
    {
      from: bram.id,
      type: "text",
      typing: typingTypes.normal.quick,
      content: "Haha yeah they can be crazy on the tape",
      delay: 1,
    },
    {
      from: crystal.id,
      type: "image",
      typing: typingTypes.normal.medium,
      content: "Ok no scissors so I have to use my knife 😬",
      url: new URL(
        "/assets/images/chats/tortilla/knife.jpg?width=600",
        import.meta.url
      ).toString(),
      delay: 1,
    },
    {
      from: crystal.id,
      type: "text",
      typing: typingTypes.normal.quick,
      content: "Sooo I will be extra careful baba!",
      delay: 1,
    },
    {
      from: crystal.id,
      type: "text",
      typing: typingTypes.normal.quick,
      content: "Uh oh did you fall asleep?!",
      delay: 3,
    },
    {
      from: bram.id,
      type: "text",
      typing: typingTypes.normal.medium,
      content: "Aaaaah yesss!!!",
      delay: 2,
    },
    {
      from: crystal.id,
      type: "text",
      typing: typingTypes.normal.quick,
      content: "Shoot it is your bedtime sorry love!",
      delay: 1,
    },
    {
      from: bram.id,
      type: "text",
      typing: typingTypes.normal.quick,
      content: "I love this photo ❤️",
      delay: 1,
    },
    {
      from: crystal.id,
      type: "text",
      typing: typingTypes.normal.quick,
      content: "Haha ok you're here! Yay! K I'm gonna open it now 😘",
      delay: 1,
    },
    {
      from: bram.id,
      type: "text",
      typing: typingTypes.normal.quick,
      content: "Perfect!!",
      delay: 1,
    },
    {
      from: crystal.id,
      type: "text",
      typing: typingTypes.normal.medium,
      content: "Carefully but quickly because I know it is babas bedtime",
      delay: 1,
    },
    {
      from: crystal.id,
      type: "text",
      typing: typingTypes.normal.quick,
      content: "And I have to work 😅",
      delay: 1,
    },
    {
      from: bram.id,
      type: "text",
      typing: typingTypes.normal.quick,
      content: "You can become my burrito baby :))",
      delay: 1,
    },
    {
      from: crystal.id,
      type: "image",
      typing: typingTypes.normal.medium,
      content: "Your happy fuzzy rosy burrito kitten 🌯❤️🐱❤️🌯",
      url: new URL(
        "/assets/images/chats/tortilla/wrapped.jpg?width=600",
        import.meta.url
      ).toString(),
      delay: 1,
    },
    {
      from: bram.id,
      type: "text",
      typing: typingTypes.normal.quick,
      content: "AAAAAHHH!! My babababababy!!",
      delay: 1,
    },
    {
      from: bram.id,
      type: "text",
      typing: typingTypes.normal.quick,
      content: "Sooo fluffy!",
      delay: 1,
    },
    {
      from: bram.id,
      type: "text",
      typing: typingTypes.normal.quick,
      content: "😍😍😍😍😍😍",
      delay: 1,
    },
    {
      from: crystal.id,
      type: "image",
      typing: typingTypes.normal.medium,
      content: "Thank you kisses for my baba!!!",
      url: new URL(
        "/assets/images/chats/tortilla/wrapped.gif?width=600",
        import.meta.url
      ).toString(),
      delay: 1,
    },
    {
      from: crystal.id,
      type: "text",
      typing: typingTypes.normal.quick,
      content: "Can't wait to be your little burrito!!!",
      delay: 1,
    },
    {
      from: bram.id,
      type: "text",
      typing: typingTypes.normal.quick,
      content: "Hmm looks great on you, sooo tasty 😋",
      delay: 1,
    },
    {
      from: crystal.id,
      type: "text",
      typing: typingTypes.normal.quick,
      content: "And someday I will learn how to make my gifs less pixely 🫣",
      delay: 1,
    },
    {
      from: crystal.id,
      type: "text",
      typing: typingTypes.normal.quick,
      content: "Baba thank you sooooo much!!!!! 😍😍😍😍",
      delay: 1,
    },
    {
      from: bram.id,
      type: "text",
      typing: typingTypes.normal.quick,
      content: "Hahaha I love aaaall the pixels my love ❤️",
      delay: 1,
    },
    {
      from: crystal.id,
      type: "text",
      typing: typingTypes.normal.quick,
      content: "I love it and I love you!",
      delay: 1,
    },
    {
      from: crystal.id,
      type: "text",
      typing: typingTypes.normal.quick,
      content: "And it really looks like a tortilla!!!!!",
      delay: 1,
    },
    {
      from: bram.id,
      type: "text",
      typing: typingTypes.normal.quick,
      content: "You're soo welcome! I wanted you to have that :))",
      delay: 1,
    },
    {
      from: crystal.id,
      type: "text",
      typing: typingTypes.normal.quick,
      content:
        "When you get here are you going to wrap me up in it and make me YOUR burrito?",
      delay: 1,
    },
    {
      from: crystal.id,
      type: "text",
      typing: typingTypes.normal.quick,
      content:
        "Aaaand then after that, are you going to wrap US in it and make us OUR burrito?",
      delay: 1,
    },
    {
      from: bram.id,
      type: "text",
      typing: typingTypes.normal.quick,
      content: "Hmmm yes of course my baby!",
      delay: 1,
    },
    {
      from: bram.id,
      type: "text",
      typing: typingTypes.normal.quick,
      content: "oooooh yes!! I cannot wait for this ❤️",
      delay: 1,
    },
  ],
};
