import { Playable } from "../shared";
import { meetCute } from "./moments/after-meet-cute";
import { create } from "zustand/esm";
import { afterFirstDate } from "./moments/after-first-date";
import { tortillaBlanket } from "./moments/tortilla-blanket";
import { useMomentStore } from "./moment.store";
import { netherlands } from "./stories/netherlands";
import { useStoryStore } from "./story.store";
import { Story } from "./stories";
import { pets } from "./stories/pets";
import { gibby } from "./moments/gibby";

const sequence: Array<Playable> = [
  meetCute,
  netherlands,
  afterFirstDate,
  gibby,
  pets,
  tortillaBlanket,
];

interface SequenceStoreState {
  fullSequence: Array<Playable>;
  currentlyPlaying: Playable | null;
  currentIndex: number;
  play: () => Promise<void>;
}

export const useSequenceStore = create<SequenceStoreState>((set, get) => ({
  fullSequence: sequence,
  currentlyPlaying: null,
  currentIndex: 0,

  async play() {
    const sequence = get().fullSequence;

    for (const playable of sequence) {
      set({
        currentlyPlaying: playable,
      });
      if (playable.type === "moment") {
        await useMomentStore.getState().playMoment(playable);
      }

      if (playable.type === "story") {
        await useStoryStore.getState().playStory(playable as Story);
      }
    }
  },
}));
