import React from "react";
import { useRpx } from "../../shared";

export default function ChatAvatar({ src }: { src: string }) {
  const rpx = useRpx();

  return (
    <div
      className={"overflow-hidden rounded-full bg-neutral-300"}
      style={{
        width: rpx(80),
        height: rpx(80),
        border: rpx(4) + " solid rgba(255, 255, 255, 0.1)",
      }}
    >
      <img src={src} className={"h-full w-full object-cover"} alt="" />
    </div>
  );
}
