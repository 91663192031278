import { typingTypes } from "../../shared";
import { bram, crystal, Moment } from "../moments";

export const meetCute: Moment = {
  type: "moment",
  id: "meet-cute",
  title: "Meet Cute",
  description: [
    "Much like a movie🎥 they had a real meet cute",
    "This is after they met in an Uber Pool",
  ],
  startDate: "2020-01-01",
  persons: [crystal, bram],
  chats: [
    {
      typing: typingTypes.normal.quick,
      content: "Nice to meet you, Bram!",
      delay: 0,
      from: crystal.id,
      type: "text",
    },
    {
      typing: typingTypes.normal.quick,
      content:
        "Let me know if you'd like to grab a drink sometime this week 😊",
      delay: 1,
      from: crystal.id,
      type: "text",
    },
    {
      typing: typingTypes.retyping.once,
      content: "Hi Christel! Great to meet you ;) yes let's do it!",
      delay: 1,
      from: bram.id,
      type: "text",
    },
    {
      typing: typingTypes.retyping.once,
      content:
        "When would work well for you? I'm still pretty flexible in the evenings 🙂",
      delay: 1,
      from: bram.id,
      type: "text",
    },
    {
      typing: typingTypes.normal.quick,
      content: "Okay! I am free Tuesday :) How is Tuesday evening for you?",
      delay: 2,
      from: crystal.id,
      type: "text",
    },
    {
      typing: typingTypes.normal.medium,
      content:
        "Yes that works, I've put it in my calendar! You talked about Sunset Trocadero, grab drinks there?",
      delay: 1,
      from: bram.id,
      type: "text",
    },
    {
      typing: typingTypes.normal.medium,
      content:
        "Sure that's perfect! They open at 6pm. I can text if I am free a little bit earlier than that.",
      delay: 1,
      from: crystal.id,
      type: "text",
    },
    {
      typing: typingTypes.normal.medium,
      content:
        "Also, I prefer using WhatsApp! If you want to add me there also, feel free. 😊 My name is spelled  Crystal Frost.",
      delay: 0,
      from: crystal.id,
      type: "text",
    },
  ],
};
