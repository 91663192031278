import { Chat, Moment, Person } from "./moments";
import { create } from "zustand";

interface MomentStoreState {
  messages: Array<Chat>;
  currentMoment: Moment | null;
  messageIndex: number;
  personsTyping: Array<Person>;

  _restart: () => void;
  _handleTyping: (cm: Chat) => void;

  _getCurrentMessage: () => Chat;
  _playMessage: () => void;
  startMessages: () => void;

  setMoment: (moment: Moment) => void;
  playMoment: (moment: Moment) => Promise<void>;
  nextMessage: () => void;
  setPersonsTyping: (persons: Array<Person>) => void;
  isPersonTyping: (person: Person) => boolean;

  onComplete: () => void;
}

export const useMomentStore = create<MomentStoreState>((set, get) => ({
  messages: [],
  currentMoment: null,
  messageIndex: 0,
  personsTyping: [],
  onComplete: () => null,

  setMoment: (moment) => set({ currentMoment: moment }),
  playMoment: (moment) => {
    set({ currentMoment: moment });
    set({ messageIndex: 0, messages: [] });
    // get()._playMessage();

    // handle finish...
    return new Promise((resolve, reject) => {
      set({
        onComplete: resolve,
      });
    });
  },

  _restart: () => {
    get().playMoment(get().currentMoment!);
  },

  startMessages() {
    get()._playMessage();
  },

  _playMessage: () => {
    const cm = get()._getCurrentMessage();
    if (!cm) {
      doAfter(3000, () => {
        set({
          currentMoment: null,
        });
        get().onComplete();
      });

      return;
    }
    const typingDuration = cm?.typing?.reduce(
      (acc, curr) => acc + curr.duration,
      0
    );
    const totalDuration = typingDuration + cm.delay;
    get()._handleTyping(cm);

    doAfter(totalDuration * 1000, () => {
      set({ messages: [...get().messages, cm] });
    });

    setTimeout(() => {
      set({
        messageIndex: get().messageIndex + 1,
      });
      get()._playMessage();
    }, totalDuration * 1000);
  },

  _handleTyping: (cm: Chat) => {
    // make list of typing where it increments the time with the last one
    const person = get().currentMoment.persons.find((p) => p.id === cm.from);
    let totalThusFar = 0;

    const typing = cm.typing?.map((t) => {
      totalThusFar += t.duration;
      return { ...t, duration: totalThusFar - t.duration };
    });

    typing.forEach((t) => {
      doAfter(t ? t.duration * 1000 : 0, () => {
        set({
          personsTyping: t.type === "type" ? [person] : [],
        });
      });
    });
  },

  _getCurrentMessage: () => {
    const { currentMoment, messageIndex } = get();
    return currentMoment?.chats?.[messageIndex];
  },
  nextMessage: () => set((state) => ({ messageIndex: state.messageIndex + 1 })),
  setPersonsTyping: (persons) => set({ personsTyping: persons }),
  isPersonTyping: (person) => {
    return !!useMomentStore
      .getState()
      .personsTyping.find((p) => p.id === person.id);
  },
}));

function doAfter(ms: number, fn: () => void) {
  setTimeout(fn, ms);
}
