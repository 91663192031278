import { Story } from "../stories";

export const netherlands: Story = {
  title: "2019 trip to the Netherlands",
  description: [
    "In 2019 they went to visit family in the Netherlands",
    `Bram's hometown Groningen, Amsterdam and Katwijk aan Zee`,
  ],
  type: "story",
  pages: [
    // {
    //   id: "1",
    //   type: "title",
    //   content: "TOGETHER IN THE NETHERLANDS",
    //   theme: "love",
    //   duration: 2,
    // },
    {
      id: "2",
      type: "full-screen",
      duration: 50,
      media: [
        {
          url: new URL(
            "/assets/images/stories/netherlands/IMG_8116.jpeg?width=1000&as=avif&quality=50",
            import.meta.url
          ).toString(),
          type: "image",
        },
        {
          url: new URL(
            "/assets/images/stories/netherlands/IMG_8188.jpeg?width=1000&as=avif&quality=50",
            import.meta.url
          ).toString(),
          type: "image",
        },
        {
          url: new URL(
            "/assets/images/stories/netherlands/IMG_8278.jpeg?width=1000&as=avif&quality=50",
            import.meta.url
          ).toString(),
          type: "image",
        },
        {
          url: new URL(
            "/assets/images/stories/netherlands/IMG_8370.jpeg?width=1000&as=avif&quality=50",
            import.meta.url
          ).toString(),
          type: "image",
        },
        {
          url: new URL(
            "/assets/images/stories/netherlands/IMG_8397.jpeg?width=1000&as=avif&quality=50",
            import.meta.url
          ).toString(),
          type: "image",
        },
        {
          url: new URL(
            "/assets/images/stories/netherlands/IMG_8494.jpeg?width=1000&as=avif&quality=50",
            import.meta.url
          ).toString(),
          type: "image",
        },
        {
          url: new URL(
            "/assets/images/stories/netherlands/IMG_8532.jpeg?width=1000&as=avif&quality=50",
            import.meta.url
          ).toString(),
          type: "image",
        },
        {
          url: new URL(
            "/assets/images/stories/netherlands/IMG_8706.jpeg?width=1000&as=avif&quality=50",
            import.meta.url
          ).toString(),
          type: "image",
        },
        {
          url: new URL(
            "/assets/images/stories/netherlands/IMG_8707.jpeg?width=1000&as=avif&quality=50",
            import.meta.url
          ).toString(),
          type: "image",
        },
        {
          url: new URL(
            "/assets/images/stories/netherlands/IMG_8823.jpeg?width=1000&as=avif&quality=50",
            import.meta.url
          ).toString(),
          type: "image",
        },
        {
          url: new URL(
            "/assets/images/stories/netherlands/IMG_8835.jpeg?width=1000&as=avif&quality=50",
            import.meta.url
          ).toString(),
          type: "image",
        },
        {
          url: new URL(
            "/assets/images/stories/netherlands/IMG_8882.jpeg?width=1000&as=avif&quality=50",
            import.meta.url
          ).toString(),
          type: "image",
        },
      ],
    },
    {
      id: "3",
      type: "masonry-scroll",
      duration: 30,
      media: [
        {
          url: new URL(
            "/assets/images/stories/netherlands/IMG_8116.jpeg?width=1000&as=avif&quality=50",
            import.meta.url
          ).toString(),
          type: "image",
        },
        {
          url: new URL(
            "/assets/images/stories/netherlands/IMG_8188.jpeg?width=1000&as=avif&quality=50",
            import.meta.url
          ).toString(),
          type: "image",
        },
        {
          url: new URL(
            "/assets/images/stories/netherlands/IMG_8278.jpeg?width=1000&as=avif&quality=50",
            import.meta.url
          ).toString(),
          type: "image",
        },
        {
          url: new URL(
            "/assets/images/stories/netherlands/IMG_8370.jpeg?width=1000&as=avif&quality=50",
            import.meta.url
          ).toString(),
          type: "image",
        },
        {
          url: new URL(
            "/assets/images/stories/netherlands/IMG_8397.jpeg?width=1000&as=avif&quality=50",
            import.meta.url
          ).toString(),
          type: "image",
        },
        {
          url: new URL(
            "/assets/images/stories/netherlands/IMG_8494.jpeg?width=1000&as=avif&quality=50",
            import.meta.url
          ).toString(),
          type: "image",
        },
        {
          url: new URL(
            "/assets/images/stories/netherlands/IMG_8532.jpeg?width=1000&as=avif&quality=50",
            import.meta.url
          ).toString(),
          type: "image",
        },
        {
          url: new URL(
            "/assets/images/stories/netherlands/IMG_8706.jpeg?width=1000&as=avif&quality=50",
            import.meta.url
          ).toString(),
          type: "image",
        },
        {
          url: new URL(
            "/assets/images/stories/netherlands/IMG_8707.jpeg?width=1000&as=avif&quality=50",
            import.meta.url
          ).toString(),
          type: "image",
        },
        {
          url: new URL(
            "/assets/images/stories/netherlands/IMG_8823.jpeg?width=1000&as=avif&quality=50",
            import.meta.url
          ).toString(),
          type: "image",
        },
        {
          url: new URL(
            "/assets/images/stories/netherlands/IMG_8835.jpeg?width=1000&as=avif&quality=50",
            import.meta.url
          ).toString(),
          type: "image",
        },
        {
          url: new URL(
            "/assets/images/stories/netherlands/IMG_8882.jpeg?width=1000&as=avif&quality=50",
            import.meta.url
          ).toString(),
          type: "image",
        },
      ],
    },
  ],
};
