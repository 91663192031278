import React, { useEffect, useRef } from "react";
import { useStore } from "./data/store";
import Chat from "./components/chat/chat";
import Stories from "./components/stories/stories";
import Confetti from "./components/confetti";
import { useSequenceStore } from "./data/sequence.store";

export default function App() {
  const { setSize } = useStore();
  const containerRef = useRef<HTMLDivElement>(null);
  const playable = useSequenceStore((s) => s.currentlyPlaying);
  const play = useSequenceStore((s) => s.play);

  function handleResize() {
    const rect = containerRef.current?.getBoundingClientRect();
    if (!rect) {
      return;
    }

    setSize({
      width: rect.width,
      height: rect.height,
    });
  }

  useEffect(() => {
    play().then(() => {
      console.log("done");
      window.location.reload();
    });
    window.addEventListener("resize", handleResize);
    handleResize();

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  // {/*aspect-tablet1*/}

  return (
    <div className={"h-screen w-screen"}>
      <div
        className={`pink-200 relative h-screen max-h-full w-screen max-w-full flex-1 overflow-hidden          
          `}
        ref={containerRef}
      >
        {playable?.type === "moment" && <Chat />}
        {playable?.type === "story" && <Stories />}
        {/*<Confetti />*/}
      </div>
    </div>
  );
}
