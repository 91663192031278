import React, { useEffect, useState } from "react";
import { useStoryStore } from "../../data/story.store";
import { AnimatePresence } from "framer-motion";
import { motion } from "framer-motion";
import { useRpx } from "../../shared";
import StoryPage from "./story-page";
import TitleCard from "../messages/title-card";

export default function Stories() {
  const [showTitleCard, setShowTitleCard] = useState(true);
  const story = useStoryStore((s) => s.story);
  const start = useStoryStore((s) => s.start);
  const rpx = useRpx();
  const playStory = useStoryStore((state) => state.playStory);
  const pageIndex = useStoryStore((state) => state.pageIndex);
  const page = useStoryStore((state) => state.getPage());

  function startStory() {
    setShowTitleCard(false);
    start();
  }

  useEffect(() => {
    // playStory(netherlands);
    // playStory(friends);
  }, []);

  return (
    <div
      className={"h-full w-full"}
      style={{
        perspective: "100vh",
      }}
    >
      <AnimatePresence>
        {story?.description && showTitleCard && (
          <TitleCard description={story.description} onComplete={startStory} />
        )}
      </AnimatePresence>

      <motion.div
        className={"absolute bottom-10 z-50 flex w-full justify-center"}
        animate={{
          opacity: showTitleCard ? 0 : 1,
        }}
      >
        <div
          className={"rounded bg-gray-400 px-8 py-2 font-bold text-white"}
          style={{
            fontSize: rpx(20),
          }}
        >
          {story?.title}
        </div>
      </motion.div>

      <AnimatePresence>
        {pageIndex !== null && (
          <motion.div
            key={pageIndex}
            className={`absolute inset-0 flex h-full w-full`}
            style={{
              transformStyle: "preserve-3d",
              translateZ: "-0vw",
            }}
            initial={{
              rotateY: 90,
              translateX: "50%",
              opacity: 0.6,
              zIndex: 1,
            }}
            animate={{
              rotateY: 0,
              translateX: "0%",
              opacity: 1,
              zIndex: 1,
            }}
            exit={{
              rotateY: -90,
              translateX: "-50%",
              opacity: 0,
              zIndex: 0,
            }}
            transition={{
              duration: 0.5,
            }}
          >
            {page && <StoryPage page={page} />}
          </motion.div>
        )}
      </AnimatePresence>
    </div>
  );
}
