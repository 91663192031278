import React, { CSSProperties } from "react";
import { Media } from "../data/stories";

export default function Media({
  media,
  style,
}: {
  media: Media;
  style?: CSSProperties;
}) {
  return (
    <>
      {media.type === "image" && (
        <img style={style} src={media.url} alt="image" />
      )}

      {media.type === "video" && (
        <video src={media.url} autoPlay style={style} loop muted />
      )}
    </>
  );
}
