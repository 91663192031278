import { create } from "zustand";
import { Chat, Moment, Person, persons } from "./moments";

interface WindowSize {
  width: number;
  height: number;
}

interface GeneralStoreState {
  size: WindowSize | null;
  setSize: (size: WindowSize) => void;
}

export const useStore = create<GeneralStoreState>()((set) => ({
  size: null,
  setSize: (size) => set({ size }),
}));
