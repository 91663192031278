import React, { useEffect, useRef, useState } from "react";
import { Page } from "../../data/stories";
import Masonry from "react-responsive-masonry";
import Media from "../media";
import { useRpx } from "../../shared";
import { motion } from "framer-motion";

export default function MasonryScroll({ page }: { page: Page }) {
  const [scrollTo, setScrollTo] = useState<number>(0);
  const masonryContainer = useRef<HTMLDivElement>(null);
  const pageContainer = useRef<HTMLDivElement>(null);
  const rpx = useRpx();

  useEffect(() => {
    if (
      masonryContainer.current?.scrollHeight &&
      pageContainer.current?.clientHeight
    ) {
      setTimeout(() => {
        setScrollTo(
          masonryContainer.current.scrollHeight -
            pageContainer.current.clientHeight
        );
      }, 500);
    }
  }, []);

  return (
    <div className={"h-full w-full bg-pink-200"} ref={pageContainer}>
      <motion.div
        ref={masonryContainer}
        animate={{
          y: -scrollTo,
        }}
        transition={{
          duration: page.duration - 2,
        }}
      >
        <Masonry columnsCount={2} gutter={rpx(5)}>
          {page.media.map((media, index) => (
            <motion.div
              key={index}
              className={"overflow-hidden rounded"}
              initial={{
                opacity: 0,
                x: index % 2 === 0 ? `${rpx(-20)}` : `${rpx(20)}`,
              }}
              whileInView={{
                opacity: 1,
                x: `${rpx(0)}`,
              }}
              viewport={{
                amount: 0.7,
              }}
              transition={{
                duration: 0.4,
                delay: index * 0.1,
              }}
            >
              <Media media={media} />
            </motion.div>
          ))}
        </Masonry>
      </motion.div>
    </div>
  );
}
