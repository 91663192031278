import { Chat, crystal, Person } from "../../data/moments";
import React, { useMemo } from "react";
import { useRpx } from "../../shared";
import Typography from "../typography/typography";
import { motion } from "framer-motion";
import { useMomentStore } from "../../data/moment.store";
import MessageMedia from "./message-media";
import { Message } from "postcss";

export default function Message({ message }: { message: Chat }) {
  const { persons } = useMomentStore((state) => state.currentMoment);

  const isRight = useMemo(() => {
    return message.from !== persons?.[0].id;
  }, [persons]);
  const rpx = useRpx();

  const person = useMemo<Person>(() => {
    return persons?.find((p) => p.id === message.from);
  }, []);

  if (message.from === "system") {
    return <SystemMessage message={message} />;
  }

  return (
    <motion.div
      className={`flex ${isRight ? "justify-end" : "justify-start"}`}
      style={{
        transformOrigin: isRight ? "right" : "left",
      }}
      initial={{
        opacity: 0,
        y: 20,
        rotate: 30,
        scale: 0,
      }}
      animate={{
        opacity: 1,
        y: 0,
        scale: 1,
        rotate: 0,
      }}
      transition={{
        type: "spring",
        duration: 0.3,
        mass: 0.5,
        stiffness: 120,
      }}
    >
      <div
        style={{
          maxWidth: "70%",
          margin: `${rpx(10)} ${rpx(20)}`,
          padding: `${rpx(6)} ${rpx(12)}`,
          borderRadius: rpx(20),
          background: person?.color,
          color: person.secondaryColor,
        }}
      >
        {message.type !== "text" && <MessageMedia message={message} />}
        <Typography size={32}>{message.content}</Typography>
      </div>
    </motion.div>
  );
}

function SystemMessage({ message }: { message: Message }) {
  const rpx = useRpx();

  return (
    <motion.div
      className={`flex w-full bg-neutral-300 text-neutral-600`}
      style={{
        margin: `${rpx(30)} 0`,
      }}
      initial={{
        opacity: 0,
        y: 20,
      }}
      animate={{
        opacity: 1,
        y: 0,
      }}
      transition={{
        type: "spring",
        duration: 0.3,
        mass: 0.5,
        stiffness: 120,
      }}
    >
      <div
        style={{
          margin: `${rpx(10)} ${rpx(20)}`,
          padding: `${rpx(6)} ${rpx(20)}`,
          borderRadius: rpx(20),
        }}
        className={"text-white"}
      >
        <Typography size={32}>{message.content}</Typography>
      </div>
    </motion.div>
  );
}
