import React, { useEffect } from "react";
import ChatAvatar from "./chat-avatar";
import { useRpx } from "../../shared";
import Typography from "../typography/typography";
import { motion } from "framer-motion";
import { useMomentStore } from "../../data/moment.store";
import { Person, persons } from "../../data/moments";

export default function ChatHeader() {
  const rpx = useRpx();
  const [status, setStatus] = React.useState<string>(null);
  const personsTyping = useMomentStore((state) => state.personsTyping);
  const { persons } = useMomentStore((state) => state.currentMoment) ?? {};

  useEffect(() => {
    setTimeout(() => {
      setStatus("Online");
    }, 1000);
  });

  if (!persons) {
    return null;
  }

  return (
    <div
      className={
        "absolute top-0 z-0 flex w-full justify-between bg-pink-100 text-teal-800"
      }
      style={{
        padding: rpx(16),
        // large shadow for the header using rpx for the shadow
        boxShadow: `0px 0px ${rpx(12)} ${rpx(7)} rgba(0, 0, 0, 0.2)`,
      }}
    >
      <AvatarSection
        person={persons?.[0]}
        status={
          personsTyping.find((e) => e.id === "crystal") ? "Typing..." : null
        }
        position={"left"}
      />

      <AvatarSection
        person={persons?.[1]}
        status={personsTyping.find((e) => e.id === "bram") ? "Typing..." : null}
        position={"right"}
      />
    </div>
  );
}

function AvatarSection({
  person,
  position,
  status,
}: {
  person: Person;
  status: string;
  position: "left" | "right";
}) {
  const rpx = useRpx();

  return (
    <div
      className={`flex items-center ${
        position === "right" && "flex-row-reverse"
      }`}
      style={{
        gap: rpx(16),
      }}
    >
      <ChatAvatar src={person.avatar} />
      <div className={position === "right" ? "text-right" : ""}>
        <Typography className={"font-medium"} size={24}>
          {person.name}
        </Typography>

        <motion.div
          animate={{
            height: status ? rpx(24) : 0,
          }}
        >
          <Typography size={18} className={"text-teal-600"}>
            {status}
          </Typography>
        </motion.div>
      </div>
    </div>
  );
}
